import React from "react"
import PillIcon from "mdi-react/PillIcon"

import HeartPulseIcon from "mdi-react/HeartPulseIcon"
  

import PageBody from "../../components/page-body/pageBody"
import SEO from "../../components/seo"

import Banner from "../../components/banner/banner"
import physicalMentalHealth from "../../images/components/physical-mental-health.jpg"
import physicalMentalHealthBody1 from "../../images/components/physical-mental-health-body-1.jpg"
import physicalMentalHealthBody2 from "../../images/components/physical-mental-health-body-2.jpg"

import BorderTitle from "./../../components/border-title/borderTitle"
import ComponentNavigator from "./../../components/component-navigator/componentNavigator"
import QuoteImages from "./../../components/quote-images/quoteImages"

import "./program-wide-events-to-promote-physical-and-mental-health-of-seniors.scss"

var ProgramWideEventsToPromotePhysicalAndMentalHealthOfSeniors = props => (
  <>
    <SEO title="Home" keywords={["gatsby", "application", "react"]} />
    <div className="program-wide-events-to-promote-physical-and-mental-health-of-seniors-page">
      <Banner
        src={physicalMentalHealth}
        title="Program-Wide Events to Promote Physical and Mental Health of Seniors"
        height="32.5rem"
        heightMobile="120vw"
        backgroundPosY="70%"
      />
      <PageBody className="page">
        <BorderTitle>
          Program-Wide Events to Promote Physical and Mental Health of Seniors
        </BorderTitle>
        <div className="body">
          <img className="left" src={physicalMentalHealthBody1} alt="" /><p>The Health-Smart Program has program-wide activities that include Food Cooking and Demonstration Events, a Health-Smart Walk, and Mental and Physical Health-Related Events. All these activities serve to provide direct opportunities for health education and physical activity, as well as times for social connections.</p><img className="right" src={physicalMentalHealthBody2} alt="" /><p>Lastly, the Health-Smart Expert Panels bring the participants together with health experts and other community members to discuss health-related questions these participating seniors might have.  Panelists can include medical professionals, dietitians, mental health professionals, past Health-Smart participants, physical activity experts, and/or community members who have made healthy changes to their lifestyle.</p>
        </div>
        
        <div className="navigation">
        <ComponentNavigator
            link="/components-year-one/health-smart-behavior-program-customized-for-seniors"
            className="first"
            icon={HeartPulseIcon}
            title="Health-Smart Behavior Program Customized for Seniors"
            />
          <ComponentNavigator
            next
            link="/components-year-one/assistance-with-enrolling-in-health-insurance-program-of-choice"
            icon={PillIcon}
            title="Assistance with Enrolling in Health Insurance Program of Choice"
          />
        </div>
      </PageBody>
    </div>
  </>
  )

export default ProgramWideEventsToPromotePhysicalAndMentalHealthOfSeniors
